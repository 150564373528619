<template>
    <el-dialog tabindex="-1" width="800px" top="40px" title="Granska noter" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false">
        <div class="border border-indigo-600 p-4" v-if="data" style="min-height: 1200px;">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>2 (8)</p>
            </div>
            <div class="px-8 pb-8 break-normal">
                <div v-for="(card, index) in data.cards" :key="index">
                    <div v-if="card != null">
                        <div v-if="card.accountingAndValuationPrinciples != null && card.accountingAndValuationPrinciples.addAccountingAndValuationPrinciple">
                            <h2 class="pt-4 text-base font-bold">{{ card.accountingAndValuationPrinciples.text }}</h2>
                        </div>

                        <div v-if="card.generalClarifications != null && card.generalClarifications.addGeneralClarification">
                            <h3 class="pt-4 font-bold font-sm">{{ card.generalClarifications.headTitle }}</h3>
                            <p>{{ card.generalClarifications.texts.options[card.generalClarifications.texts.selected].text }}</p>
                        </div>

                        <div v-if="card.serviceAndContractAssignments != null && card.serviceAndContractAssignments.addServiceAndContractAssignment">
                            <h3 class="pt-4 font-bold font-sm">{{ card.serviceAndContractAssignments.headTitle }}</h3>
                            <p>{{ card.serviceAndContractAssignments.texts.options[card.serviceAndContractAssignments.texts.selected].text }}</p>
                        </div>

                        <div v-if="card.fixedAssets != null && card.fixedAssets.addFixedAsset">
                            <h3 class="pt-4 font-bold font-sm">{{ card.fixedAssets.headTitle }}</h3>
                            <div class="space-y-2 break-normal">
                                <p v-if="card.fixedAssets.addDepreciation">{{ card.fixedAssets.depreciationText }}</p>
                                <p v-if="card.fixedAssets.addResidualDepreciation">{{ card.fixedAssets.residualDepreciationText }}</p>
                                <p v-if="card.fixedAssets.addStraightLineDepreciation">
                                    {{ card.fixedAssets.straightLineDepreciationText }}
                                </p>
                            </div>
                            <div v-if="card.fixedAssets.addIntangibleAssets">
                                <h3 class="pt-4 font-bold italic font-sm">{{ card.fixedAssets.intangibleAssets.title }}</h3>
                                <table>
                                    <tbody>
                                        <tr v-for="(item, index) in card.fixedAssets.intangibleAssets.deprecationPerItems" :key="index">
                                            <td width="450">
                                                {{ item.description }}
                                            </td>
                                            <td>
                                                {{ item.depreciationTime }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="pt-2" v-if="card.fixedAssets.intangibleAssets.addGoodwill">{{ card.fixedAssets.intangibleAssets.goodwillText }}</p>
                            </div>
                            <div v-if="card.fixedAssets.addTangibleAssets">
                                <h3 class="pt-4 font-bold italic font-sm">{{ card.fixedAssets.tangibleAssets.title }}</h3>
                                <table>
                                    <tbody>
                                        <tr v-for="(item, index) in card.fixedAssets.tangibleAssets.deprecationPerItems" :key="index">
                                            <td width="450">
                                                {{ item.description }}
                                            </td>
                                            <td>
                                                {{ item.depreciationTime }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p class="pt-2" v-if="card.fixedAssets.tangibleAssets.addNonLinearDepreciation">{{ card.fixedAssets.tangibleAssets.nonLinearDepreciationText }}</p>
                                <p class="pt-2" v-if="card.fixedAssets.tangibleAssets.addResidualDepreciation">{{ card.fixedAssets.tangibleAssets.residualDepreciationText }}</p>
                            </div>
                        </div>

                        <div v-if="card.keyFigureDefinitions != null && card.keyFigureDefinitions.addKeyFigureDefinition">
                            <h3 class="pt-4 font-bold font-sm">{{ card.keyFigureDefinitions.headTitle }}</h3>
                            <p class="break-normal" v-html="card.keyFigureDefinitions.text.replace(/\n/g, '<br>')" />
                        </div>

                        <div v-if="card.newCards != null">
                            <h3 class="pt-4 font-bold font-sm">{{ card.newCards.headTitle }}</h3>
                            <div class="space-y-2 break-normal">
                                <table v-if="card.newCards.addTable">
                                    <tbody>
                                        <tr v-for="(item, index) in card.newCards.tableRows" :key="index">
                                            <td width="450">
                                                {{ item.columnOne }}
                                            </td>
                                            <td>
                                                {{ item.columnTwo }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>{{ card.newCards.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="border border-indigo-600 p-4 mt-8" v-if="notesData && isNotes" style="min-height: 1200px;">
            <div class="flex justify-between p-8">
                <div class="flex flex-col">
                    <p>{{ clientInfo.name }}</p>
                    <p>Org.nr {{ clientInfo.registrationNumber }}</p>
                </div>
                <p>2 (8)</p>
            </div>
            <div class="px-8 pb-8 break-normal">
                <div v-for="(card, index) in notesData.cards" :key="index">
                    <div v-if="card.averageNumberOfEmployees != null && card.averageNumberOfEmployees.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.averageNumberOfEmployees.notesNumber }} {{ card.averageNumberOfEmployees.headTitle }}</h2>
                        <TableHeadComponent :data="notesData" />
                        <table>
                            <tbody>
                                <tr>
                                    <td width="350">
                                        <p>{{ card.averageNumberOfEmployees.averageNumberOfEmployeesTableRows[0].description }}</p>
                                    </td>
                                    <td width="150">
                                        <p class="flex justify-end">{{ card.averageNumberOfEmployees.averageNumberOfEmployeesTableRows[0].currentYear }}</p>
                                    </td>
                                    <td width="150">
                                        <p class="flex justify-end">{{ card.averageNumberOfEmployees.averageNumberOfEmployeesTableRows[0].previousYear }}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div v-if="card.equipmentToolsAndInstallations != null && card.equipmentToolsAndInstallations.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.equipmentToolsAndInstallations.notesNumber }} {{ card.equipmentToolsAndInstallations.headTitle }}</h2>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.equipmentToolsAndInstallations.acquisitionValuesTableRows" key-name="acquisition-" />
                        <TableComponent :card-rows="card.equipmentToolsAndInstallations.depreciationTableRows" key-name="depreciation-" />
                        <TableComponent v-if="card.equipmentToolsAndInstallations.addWriteUpsTable" :card-rows="card.equipmentToolsAndInstallations.writeUpsTableRows" key-name="writeups-" />
                        <TableComponent v-if="card.equipmentToolsAndInstallations.addWriteDownsTable" :card-rows="card.equipmentToolsAndInstallations.writeDownsTableRows" key-name="writedowns-" />
                        <TableComponent :card-rows="card.equipmentToolsAndInstallations.closingReportedValueTableRows" key-name="closingreport-" />
                        <p>{{ card.equipmentToolsAndInstallations.text }}</p>
                    </div>

                    <div v-if="card.improvementExpensesOtherProperties != null && card.improvementExpensesOtherProperties.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.improvementExpensesOtherProperties.notesNumber }} {{ card.improvementExpensesOtherProperties.headTitle }}</h2>
                        <p>{{ card.improvementExpensesOtherProperties.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.improvementExpensesOtherProperties.acquisitionValuesTableRows" key-name="acquisition-" />
                        <TableComponent :card-rows="card.improvementExpensesOtherProperties.depreciationTableRows" key-name="depreciation-" />
                        <TableComponent v-if="card.improvementExpensesOtherProperties.addWriteUpsTable" :card-rows="card.improvementExpensesOtherProperties.writeUpsTableRows" key-name="writeups-" />
                        <TableComponent
                            v-if="card.improvementExpensesOtherProperties.addWriteDownsTable"
                            :card-rows="card.improvementExpensesOtherProperties.writeDownsTableRows"
                            key-name="writedowns-"
                        />
                        <TableComponent :card-rows="card.improvementExpensesOtherProperties.closingReportedValueTableRows" key-name="closingreport-" />
                    </div>

                    <div v-if="card.otherLongTermReceivables != null && card.otherLongTermReceivables.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.otherLongTermReceivables.notesNumber }} {{ card.otherLongTermReceivables.headTitle }}</h2>
                        <p>{{ card.otherLongTermReceivables.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.otherLongTermReceivables.acquisitionValuesTableRows" key-name="acquisition-" />
                        <TableComponent :card-rows="card.otherLongTermReceivables.depreciationTableRows" key-name="depreciation-" />
                        <TableComponent v-if="card.otherLongTermReceivables.addWriteUpsTable" :card-rows="card.otherLongTermReceivables.writeUpsTableRows" key-name="writeups-" />
                        <TableComponent v-if="card.otherLongTermReceivables.addWriteDownsTable" :card-rows="card.otherLongTermReceivables.writeDownsTableRows" key-name="writedowns-" />
                        <TableComponent :card-rows="card.otherLongTermReceivables.closingReportedValueTableRows" key-name="closingreport-" />
                        <p>
                            Av årets nedsrkivningar avser {{ card.otherLongTermReceivables.amount }} kronor nedskrivningar till det värde som tillgången har på balansdagen även om det inte kan antas
                            att värdenedgången är bestående.
                        </p>
                    </div>

                    <div v-if="card.longTermLiabilities != null && card.longTermLiabilities.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.longTermLiabilities.notesNumber }} {{ card.longTermLiabilities.headTitle }}</h2>
                        <p>{{ card.longTermLiabilities.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <div v-if="card.longTermLiabilities.addLongTermLiabilitiesFiveYear">
                            <p class="font-bold">{{ card.longTermLiabilities.longTermLiabilitiesFiveYears.title }}</p>
                            <TableComponent :card-rows="card.longTermLiabilities.longTermLiabilitiesFiveYears.longTermLiabilitiesTableRows" key-name="longTermLiabilitiesFiveYears-" />
                        </div>
                        <div>
                            <p class="font-bold">{{ card.longTermLiabilities.longTermLiabilitiesOneYears.title }}</p>
                            <TableComponent :card-rows="card.longTermLiabilities.longTermLiabilitiesOneYears.longTermLiabilitiesTableRows" key-name="longTermLiabilitiesOneYears-" />
                        </div>
                    </div>

                    <div v-if="card.checkAccountCredits != null && card.checkAccountCredits.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.checkAccountCredits.notesNumber }} {{ card.checkAccountCredits.headTitle }}</h2>
                        <p>{{ card.checkAccountCredits.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.checkAccountCredits.checkAccountCreditTableRows" key-name="checkAccountCredit-" />
                        <TableComponent :card-rows="card.checkAccountCredits.specificationTableRows" key-name="specification-" />
                    </div>

                    <div v-if="card.contingentLiabilities != null && card.contingentLiabilities.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.contingentLiabilities.notesNumber }} {{ card.contingentLiabilities.headTitle }}</h2>
                        <p>{{ card.contingentLiabilities.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.contingentLiabilities.contingentLiabilitiesTableRows" key-name="contingentLiabilities-" />
                    </div>

                    <div v-if="card.placedSecurities != null && card.placedSecurities.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.placedSecurities.notesNumber }} {{ card.placedSecurities.headTitle }}</h2>
                        <p>{{ card.placedSecurities.text }}</p>
                        <TableHeadComponent :data="notesData" />
                        <TableComponent :card-rows="card.placedSecurities.placedSecuritiesTableRows" key-name="placedSecurities-" />
                    </div>

                    <div v-if="card.significantEventsAfterYearEnds != null && card.significantEventsAfterYearEnds.add">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.significantEventsAfterYearEnds.notesNumber }} {{ card.significantEventsAfterYearEnds.headTitle }}</h2>
                        <p>{{ card.significantEventsAfterYearEnds.text }}</p>
                    </div>

                    <div v-if="card.newCards != null">
                        <h2 class="pt-4 text-base font-bold">Not {{ card.newCards.notesNumber }} {{ card.newCards.headTitle }}</h2>
                        <p>{{ card.newCards.text }}</p>
                        <TableComponent v-if="card.newCards.addTable" :card-rows="card.newCards.tableRows" key-name="newCard-" />
                        <div v-if="card.newCards.addTable" class="pb-4">
                            <table>
                                <tbody>
                                    <tr v-for="(row, index) in card.newCards.tableRows" :key="'newCard-' + index">
                                        <td width="200">
                                            <p>{{ row.columnOne }}</p>
                                        </td>
                                        <td width="200">
                                            <p class="flex justify-end">{{ row.columnTwo }}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
import moment from "moment";
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientInfo: {
            type: Object,
            default: () => {},
        },
        data: {
            type: Object,
            default: () => {},
        },
        notesData: {
            type: Object,
            default: () => {},
        },
        isNotes: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        TableHeadComponent: () => import("./NotesDraftTableHeadComponent.vue"),
        TableComponent: () => import("./NotesDraftTableComponent.vue"),
    },
    methods: {
        closeModal() {
            this.$emit("close");
        },
        formatDate(date) {
            if (!date) return " ";
            return moment(date).format("YYYY.MM.DD");
        },
        lastRow(row, index) {
            return row.length - 1 === index;
        },
    },
};
</script>

<style scoped>
.year-header {
    width: 200px;
    text-align: left;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.year-cell {
    width: 100px;
    text-align: right;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.description-cell {
    width: 200px;
    font-weight: normal;
    word-break: break-word;
    padding: 4px 10px 4px 0px;
}
.amount-cell {
    width: 100px;
    font-weight: normal;
    word-break: break-word;
    text-align: right;
    padding: 4px 10px 4px 0px;
}
</style>
